
/* import local font */
/* font src is relative to the public/ folder because that's what Nextjs uses to reference static files */
@layer base {
  @font-face {
    font-family: 'Vastago Grotesk';
    src: url('/fonts/vastago-grotesk.woff2') format('woff2');
    font-display: swap;
  }
}

/* Legacy Typography */
.typography-h1 {
  @apply text-[24px] font-bold leading-[1.2];
}

.typography-h2 {
  @apply text-[16px] font-semibold leading-[1.2];
}

.typography-h3 {
  @apply text-[14px] font-medium leading-[1.3];
}

.typography-h4 {
  @apply text-[20px] font-semibold leading-[1.5];
}

.typography-subtitle {
  @apply text-[16px] leading-[1.3];
}

.typography-body {
  @apply text-[14px] leading-[1.5];
}

.typography-tiny {
  @apply text-[12px] leading-[1.5];
}

/* Aether Typography */

.ae-typography-banner {
  @apply font-['Inter'] text-[48px] font-bold leading-[40px];
}

.ae-typography-title {
  @apply font-['Inter'] text-[16px] font-bold leading-[1.5];
}

.ae-typography-h1 {
  @apply font-['Inter'] text-[32px] font-bold leading-[40px];
}
.ae-typography-h2 {
  @apply font-['Inter'] text-[20px] font-bold leading-[24px];
}
.ae-typography-h3 {
  @apply font-['Inter'] text-[14px] font-bold leading-[18px];
}
.ae-typography-h4 {
  @apply font-['Inter'] text-[13px] font-bold leading-[16px];
}

.ae-typography-body1 {
  @apply font-['Inter'] text-[14px] font-medium leading-[1.4];
}
.ae-typography-body2 {
  @apply font-['Inter'] text-[12px] font-medium leading-[18px];
}
.ae-typography-body3 {
  @apply font-['Inter'] text-[12px] font-bold leading-[16px];
}
.ae-typography-body4 {
  @apply font-['Inter'] text-[12px] font-normal leading-[16px];
}

.ae-typography-detail1 {
  @apply font-['Inter'] text-[12px] font-semibold leading-[16px];
}
.ae-typography-detail2 {
  @apply font-['Inter'] text-[12px] font-semibold italic leading-[14px];
}
.ae-typography-detail3 {
  @apply font-['Inter'] text-[8px] font-bold leading-[12px];
}
.ae-typography-detail4 {
  @apply font-['Inter'] text-[12px] leading-[13px];
}
.ae-typography-detail5 {
  @apply font-['Inter'] text-[10px] font-semibold leading-[12px] -tracking-[0.2px];
}

.ae-typography-button1 {
  @apply font-['Inter'] text-[13px] font-bold uppercase leading-[16px];
}
.ae-typography-button2 {
  @apply font-['Inter'] text-[13px] font-bold leading-[16px];
}
.ae-typography-button3 {
  @apply font-['Inter'] text-[12px] font-bold leading-[14px];
}
.ae-typography-button4 {
  @apply font-['Inter'] text-[12px] font-bold italic leading-[14px];
}

.ae-typography-link {
  @apply text-[#014DFE] hover:text-[#014DFE] hover:underline;
}

.ae-typography h1 {
  @apply ae-typography-h1;
}
.ae-typography h2 {
  @apply ae-typography-h2;
}
.ae-typography h3 {
  @apply ae-typography-h3;
}
.ae-typography h4 {
  @apply ae-typography-h4;
}

.ae-typography p,
.ae-typography span {
  @apply ae-typography-body1;
}

.ae-typography ul {
  @apply list-disc pl-3;
}

.ae-typography ul,
.ae-typography li {
  @apply ae-typography-body2;
}

.ae-typography a {
  @apply ae-typography-link;
}

[data-theme="dark"] .ae-typography a,
a[data-theme="dark"].ae-typography {
  @apply bg-transparent text-[#2e95d3] underline;
}

/* Brandon Typography */

.b-typography-h1 {
  @apply font-['Inter'] text-[36px] font-semibold leading-[44px];
}

.b-typography-h2 {
  @apply font-['Inter'] text-[24px] font-semibold leading-[28px];
}

.b-typography-h3 {
  @apply font-['Inter'] text-[20px] font-semibold leading-[24px];
}

.b-typography-h4 {
  @apply font-['Inter'] text-[16px] font-bold leading-[24px];
}

.b-typography-h5 {
  @apply font-['Inter'] text-[16px] font-semibold leading-[24px];
}

.b-typography-h6 {
  @apply font-['Inter'] text-[14px] font-bold leading-[24px];
}

.b-typography-body1 {
  @apply font-['Inter'] text-[14px] font-medium leading-[16px];
}

.b-typography-body2 {
  @apply font-['Inter'] text-[20px] font-normal leading-[24px];
}

.b-typography-body3 {
  @apply font-['Inter'] text-[14px] font-normal leading-[21px] -tracking-[0.154px];
}

.b-typography-button1 {
  @apply font-['Inter'] text-[16px] font-semibold leading-[16px];
}

.b-typography-button1 {
  @apply font-['Inter'] text-[16px] font-semibold leading-[16px];
}

.b-typography-button2 {
  @apply font-['Inter'] text-[13px] font-semibold leading-[16px];
}

.b-typography-detail2 {
  @apply font-['Inter'] text-[12px] font-medium leading-[14px];
}

.b-typography-detail3 {
  @apply font-['Inter'] text-[12px] font-semibold leading-[14px];
}

.b-typography h1 {
  @apply b-typography-h1;
}

.b-typography h2 {
  @apply b-typography-h2;
}

.b-typography h3 {
  @apply b-typography-h3;
}

.b-typography p {
  @apply b-typography-body1;
}

/*
Brand Typography

NOTE: it's wrapped into @layer utilities because we want to have an ability to use these class names with modifiers,
like md:brand-typography-h1, see https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities
*/
@layer utilities {
  .brand-typography-h1 {
    @apply font-['Inter'] text-[32px] font-bold leading-[32px];
  }

  .brand-typography-h1-modal {
    @apply font-['Inter'] text-[32px] font-semibold leading-[1.2] -tracking-[0.64px];
  }

  .brand-typography-h2 {
    @apply font-['Inter'] text-[20px] font-bold leading-[24px];
  }

  .brand-typography-h3 {
    @apply font-['Inter'] text-[14px] font-semibold leading-[16px];
  }

  .brand-typography-h4 {
    @apply font-['Inter'] text-[24px] font-medium leading-[16px];
  }

  .brand-typography-h4-bold {
    @apply font-['Inter'] text-[24px] font-bold leading-[26px];
  }

  .brand-typography-h5 {
    @apply font-['Inter'] text-[16px] font-semibold leading-[18px];
  }

  .brand-typography-h5-bold {
    @apply font-['Inter'] text-[16px] font-bold leading-[18px];
  }

  .brand-typography-h5-black {
    @apply font-['Inter'] text-[16px] font-black leading-[22px];
  }

  .brand-typography-h6 {
    @apply font-['Inter'] text-[14px] font-bold leading-[16px];
  }

  .brand-typography-h7 {
    @apply font-['Inter'] text-[14px] font-semibold leading-[16px];
  }

  .brand-typography-body1 {
    @apply font-['Inter'] text-[16px] font-normal leading-[16px];
  }

  .brand-typography-body2 {
    @apply font-['Inter'] text-[12px] font-medium leading-[16px];
  }

  .brand-typography-body3 {
    @apply font-['Inter'] text-[14px] font-normal leading-[16px];
  }

  .brand-typography-body4 {
    @apply font-['Inter'] text-[14px] font-normal leading-[21px];
  }

  .brand-typography-body5 {
    @apply font-['Inter'] text-[12px] font-medium leading-[16px];
  }

  .brand-typography-body6 {
    @apply font-['Inter'] text-[14px] font-medium leading-6;
  }

  .brand-typography-detail1 {
    @apply font-['Inter'] text-[12px] font-bold leading-[16px];
  }

  .brand-typography-detail1-black {
    @apply font-['Inter'] text-[12px] font-bold leading-[16px];
  }

  .brand-typography-detail2 {
    @apply font-['Inter'] text-[12px] font-normal leading-[16px];
  }

  .brand-typography-detail3 {
    @apply font-['Inter'] text-[12px] font-semibold leading-[16px];
  }

  .brand-typography-detail4 {
    @apply font-['Inter'] text-[9px] font-semibold leading-[100%];
  }

  .brand-typography-d0 {
    @apply font-['Inter'] text-[36px] font-bold leading-[16px];
  }

  .brand-typography-bd2 {
    @apply font-['Inter'] text-[12px] font-medium leading-[12px];
  }
}

/*
 TipTap editor Content Styles
*/

/* Placeholder */
.ProseMirror .is-editor-empty:first-child::before {
  color: #999;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* Break all symbols in link if needed for post content viewer  */
.ProseMirror[contenteditable="false"] p a {
  @apply break-all;
}

.ProseMirror p a,
.rendered-html a:not(.google-search-suggestion a) {
  @apply ae-link font-bold;
}



.bn-editor {
  @apply !ps-0 !pe-0;
}