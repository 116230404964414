.btn {
  @apply normal-case;
}

.btn-accent {
  @apply hover:text-accent;
}

.btn-accent-secondary {
  @apply btn-accent border-accent bg-transparent text-accent hover:border-transparent hover:bg-accent-focus;
}

.btn-accent-tertiary {
  @apply btn-accent border-none bg-transparent text-accent hover:bg-accent-focus;
}

.btn-accent-tertiary[disabled] {
  @apply bg-transparent;
}

.btn-error {
  @apply bg-error-content text-error hover:bg-error hover:text-error-content;
}

.btn-error-secondary {
  @apply btn-error border-error-content bg-transparent text-error-content hover:border-transparent hover:bg-error;
}

[data-theme="dark"] .btn-ghost {
  @apply hover:bg-white/10;
}

.btn-default {
  @apply btn border-none bg-black hover:bg-black/80 active:bg-black/60;
}

.btn-ae {
  @apply btn h-[40px] min-h-[40px];
}

.btn-ae-l {
  @apply btn !h-[48px] !min-h-[48px];
}

.btn-ae-xl {
  @apply btn !h-[60px] !min-h-[60px];
}

.btn-ae-s {
  @apply btn !h-[32px] !min-h-[32px];
}

.btn-ae-default {
  @apply btn-ae;
  @apply border-none bg-black hover:bg-black/80 active:bg-black/60;
}

.btn-ae-light {
  @apply btn-ae;
  @apply border-none bg-white text-black hover:bg-white/90 active:bg-white/80;
}

/* Button which is used on the lists action on the user platform  */
.btn-ae-outline {
  @apply btn rounded px-2 font-medium;
  @apply border border-black bg-transparent text-black;
  @apply hover:border-black/80 hover:bg-black/5;
  @apply active:bg-black/10;
}

.btn-ae-outline.disabled,
.btn-ae-outline[disabled] {
  @apply pointer-events-none cursor-default;
  @apply border-[#ccc] bg-transparent text-[#ccc];
}

.btn-ae-neutral {
  @apply border-none bg-black/5 hover:bg-black/20 active:bg-black/60;
}

.btn-ae-default.disabled,
.btn-ae-default[disabled] {
  @apply pointer-events-none cursor-default;
  @apply border-transparent bg-black/10 text-[rgba(0,0,0,0.2)];
}

.btn-nofill {
  @apply btn-ae;
  @apply border-[1px] border-black/10 bg-transparent text-base-content/80;
  @apply hover:border-black/10 hover:bg-black/5;
  @apply active:bg-black active:text-white;
}

.btn-nofill.disabled,
.btn-nofill[disabled] {
  @apply pointer-events-none cursor-default;
  @apply border-transparent bg-black/5 text-[rgba(0,0,0,0.2)];
}

.btn-ae-error {
  @apply btn-ae;
  @apply border-none bg-[#FF5E3A] hover:bg-[#FF5E3A]/80 disabled:bg-[#FF5E3A]/40 disabled:text-[#CCCCCC];
}

.btn-ae-error.disabled,
.btn-ae-error[disabled] {
  @apply pointer-events-none opacity-60;
}

.btn-ae-text {
  @apply btn-ae;
  @apply btn-link text-base-content/40 no-underline hover:text-base-content/80 hover:no-underline;
  @apply transition-colors duration-300;
}

.btn-ae-text-accent {
  @apply btn-ae;
  @apply btn-link p-0 text-[#014DFE] no-underline;
  @apply transition-colors duration-300;
  @apply hover:text-[#014DFE]/80 hover:no-underline;
  @apply active:text-[#014DFE]/60;
}

.btn-ae-text-accent.disabled,
.btn-ae-text-accent[disabled] {
  @apply pointer-events-none cursor-default;
  @apply bg-transparent text-[#014DFE]/20;
}

.btn-ae-blue {
  @apply btn-ae;
  @apply border-none bg-[#014DFE] text-white hover:bg-[#014DFE]/80 active:bg-[#014DFE]/60;
}

.btn-base {
  @apply flex items-center justify-center;
  @apply rounded-[100px] px-[16px] font-medium normal-case;
}

.btn-sm-outline-black {
  @apply btn-base h-[34px] min-h-[34px] border border-black bg-transparent text-[14px] text-black;
  @apply hover:border-black/80 hover:bg-white;
  @apply active:bg-black active:text-white;
}

/* Brandon Design System */

.btn-b-white {
  @apply btn-ae;
  @apply border-none bg-white text-black hover:bg-white/80 active:bg-white/60;
}

.btn-b-white.disabled,
.btn-b-white[disabled] {
  @apply pointer-events-none;
  @apply border-transparent bg-black/10 text-[rgba(0,0,0,0.2)];
}

.btn-b-white-outline {
  @apply btn-ae;
  @apply border-white bg-transparent text-white;
  @apply hover:bg-white/90 hover:text-black;
  @apply active:bg-white/60;
}

.btn-b-white-text {
  @apply btn-ae;
  @apply btn-link text-white/80 no-underline hover:text-white hover:no-underline;
  @apply transition-colors duration-300;
}

.btn-free {
  @apply btn btn-ghost;
  @apply block h-auto bg-inherit text-left text-inherit;
  @apply block h-auto hover:border-inherit hover:bg-inherit;
}

.btn-transparent {
  @apply btn;
  @apply border-none bg-transparent text-inherit;
  @apply hover:border-none hover:bg-transparent hover:text-inherit;
}
