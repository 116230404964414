.mb-18 {
  margin-bottom: 72px;
}

.ae-link {
  @apply text-[#0F35FF] !important;
}

.capitalize-first:first-letter {
  text-transform: uppercase;
}

.driver-popover.driverjs-ae {
  @apply bg-[#5E60CE] text-white;

  .driver-popover-title {
    @apply ae-typography-h3 mb-2;
  }

  .driver-popover-description {
    @apply ae-typography-body2;
  }
}

.driver-popover.driverjs-ae .driver-popover-navigation-btns {
  @apply items-center;

  .driver-popover-prev-btn {
    @apply ae-typography-h4 btn h-[30px] min-h-[30px] border-none bg-white text-black hover:bg-white/80 active:bg-white/60;
    text-transform: uppercase;
    text-shadow: none;
  }

  .driver-popover-next-btn {
    @apply btn-ae-default ae-typography-h4 !h-[30px] !min-h-[30px];
    text-transform: uppercase;
    text-shadow: none;
  }

  /* DONE button */
  :first-child {
    @apply ae-typography-detail1 mr-auto cursor-pointer border-none text-white;
    text-transform: uppercase;
    text-shadow: none;
  }
}

/* Driver arrow color */
.driver-popover.driverjs-ae {
  .driver-popover-arrow-side-left.driver-popover-arrow {
    border-left-color: #5e60ce;
  }

  .driver-popover-arrow-side-right.driver-popover-arrow {
    border-right-color: #5e60ce;
  }

  .driver-popover-arrow-side-top.driver-popover-arrow {
    border-top-color: #5e60ce;
  }

  .driver-popover-arrow-side-bottom.driver-popover-arrow {
    border-bottom-color: #5e60ce;
  }
}
